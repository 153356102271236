import { WebTitle } from "@sencrop/ui-web";
import React from "react";
import styled from "styled-components";

type NumberElementProps = {
  unit?: string;
  unitPosition?: string;
  children?: React.ReactNode;
} & React.ComponentPropsWithoutRef<"div">;

const NumberElement = ({
  unit,
  unitPosition = "top",
  children,
  ...props
}: NumberElementProps) => {
  return (
    <Container {...props} $unitPosition={unitPosition}>
      {children && (
        <WebTitle
          color="branding-variant"
          sizes={["xxxl", "xxxl", "xxxl", "xxxl"]}
        >
          {children}
        </WebTitle>
      )}
      {unit && (
        <Unit $unitPosition={unitPosition}>
          <WebTitle size="xl" color="branding-variant">
            {unit}
          </WebTitle>
        </Unit>
      )}
    </Container>
  );
};

export default NumberElement;

type ContainerProps = {
  $unitPosition?: string;
};

const Container = styled.div<ContainerProps>`
  width: fit-content;
  display: flex;
  align-items: ${(props) =>
    props.$unitPosition === "top" ? "flex-start" : "flex-end"};
`;

type UnitProps = {
  $unitPosition?: string;
};

const Unit = styled.div<UnitProps>`
  margin-left: 4px;
  padding-top: ${(props) => (props.$unitPosition === "top" ? "0.7rem" : "0")};
  padding-bottom: ${(props) =>
    props.$unitPosition === "top" ? "0rem" : "0.6rem"};
`;
