import { media, Icon, color } from "@sencrop/ui-components";
import { graphql } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";
import NumberElement from "../components/NumberElement";
import { WebTitle, WebText } from "@sencrop/ui-web";
import { containerCss, contentCss } from "../helpers/style";

const SectionNumbers = ({
  numbers,
}: GatsbyTypes.SectionNumbersFragmentFragment) => {
  if (!numbers) {
    return null;
  }

  const isUniqueElement = numbers.length === 1;
  const hasMoreThanThreeElements = numbers.length >= 3;
  return (
    <Wrapper>
      <Main hasMoreThanThreeElements={hasMoreThanThreeElements}>
        <BackgroundIcon>
          <Icon name="sencrop-icon" size={340} />
        </BackgroundIcon>
        {isUniqueElement ? (
          <Container>
            <SimpleContainer>
              <NumberAndTitleContainer>
                <NumberElement
                  unit={numbers[0]?.unit}
                  unitPosition={numbers[0]?.unitPosition}
                  style={{ marginBottom: "0.5rem" }}
                >
                  {numbers[0]?.titleNumber}
                </NumberElement>
                <WebTitle size="m" color="primary" align="left">
                  {numbers[0]?.subtitle}
                </WebTitle>
              </NumberAndTitleContainer>
              {numbers[0]?.description?.childMarkdownRemark?.html && (
                <TextContent>
                  <WebText
                    as="div"
                    size="m"
                    color="secondary"
                    align="left"
                    semiBold
                    dangerouslySetInnerHTML={{
                      __html: numbers[0].description.childMarkdownRemark.html,
                    }}
                    style={{ whiteSpace: "pre-wrap" }}
                  />
                </TextContent>
              )}
            </SimpleContainer>
          </Container>
        ) : (
          <Container>
            <MultipleContainer
              hasMoreThanThreeElements={hasMoreThanThreeElements}
            >
              {numbers.map((number, index) => (
                <ElementContainer
                  hasMoreThanThreeElements={hasMoreThanThreeElements}
                  key={index}
                >
                  <NumberElement
                    unitPosition={number?.unitPosition}
                    unit={number?.unit}
                    style={{
                      marginBottom: "0.25rem",
                    }}
                  >
                    {number?.titleNumber}
                  </NumberElement>
                  <WebTitle
                    size="m"
                    color="primary"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    {number?.subtitle}
                  </WebTitle>
                  {number?.description?.childMarkdownRemark?.html && (
                    <div>
                      <WebText
                        as="div"
                        size="m"
                        color="secondary"
                        dangerouslySetInnerHTML={{
                          __html: number.description.childMarkdownRemark.html,
                        }}
                        style={{ whiteSpace: "pre-wrap" }}
                      />
                    </div>
                  )}
                </ElementContainer>
              ))}
            </MultipleContainer>
          </Container>
        )}
      </Main>
    </Wrapper>
  );
};

export default SectionNumbers;

export const fragment = graphql`
  fragment SectionNumbersFragment on ContentfulSectionNumbers {
    numbers {
      subtitle
      titleNumber
      unit
      unitPosition
      description {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  ${containerCss}
  ${contentCss}
  position: relative;
  margin-top: 1.5rem;
  ${media.greaterThan("tablet")`
    margin-top: 2.5rem;
    padding-left: 0;
    padding-right: 0;
  `}
`;

type MainProps = {
  hasMoreThanThreeElements: boolean;
};
const Main = styled.div<MainProps>`
  border-radius: 6px;
  position: relative;
  width: 100%;
  text-align: ${(props) =>
    props.hasMoreThanThreeElements ? "center" : "left"};
  overflow: hidden;
`;

const BackgroundIcon = styled.div`
  position: absolute;
  bottom: -35px;
  left: -90px;
  color: ${color("branding-primary-variant")};
  opacity: 0.06;
  overflow: hidden;
`;

const Container = styled.div`
  width: 100%;
  margin: auto;
  height: auto;
  background-color: ${color("background-primary")};
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  overflow: hidden;

  ${media.greaterThan("tablet")`
    padding: 2rem 2rem 3rem 2rem ;
   `};
`;

const SimpleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  ${media.greaterThan("tablet")`
    flex-direction: row;
    align-items: center;
    padding: 1rem 4rem 0.375rem 4rem;
  `}
`;

const NumberAndTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin-bottom: 0.5rem;
  > * {
    width: fit-content;
  }
  ${media.greaterThan("tablet")`
    align-items: center;
    margin-bottom: 0;
    p {
        text-align: center;
    }
 `}
`;

type MultipleContainerProps = {
  hasMoreThanThreeElements: boolean;
};

const MultipleContainer = styled.div<MultipleContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${(props) => css`
    ${media.greaterThan("tablet")`
      flex-direction: row;
      ${
        props.hasMoreThanThreeElements &&
        css`
          text-align: left;
          align-items: flex-start;
          justify-content: center;
        `
      }
    `}
  `}
`;

type ElementContainerProps = {
  hasMoreThanThreeElements: boolean;
};

const ElementContainer = styled.div<ElementContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.hasMoreThanThreeElements ? "center" : "flex-start"};
  text-align: ${(props) =>
    props.hasMoreThanThreeElements ? "center" : "left"};
  flex: 1;
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
  ${media.greaterThan("tablet")`
    margin-right: 1.5rem;
    margin-bottom: 0;
    &:last-child {
        margin-right: 0;
    }
    ${(props: any) =>
      props.hasMoreThanThreeElements
        ? css`
            max-width: 290px;
          `
        : css`
            max-width: 390px;
          `}
  `}
`;

const TextContent = styled.div`
  ${media.greaterThan("tablet")`
    margin-left: 2.25rem;
    margin-bottom: 0;
  `}
`;
